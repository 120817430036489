@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 65.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #48290a;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
  border-top: 2px solid #ffa243;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  transition: 0.5s ease-in-out;
  width: 100%;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fdf8f4;
  padding: 15px 0;
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  #header .hedwrap {
    padding: 15px 10px;
  }
}

@media screen and (max-width: 834px) {
  #header .hedwrap {
    display: block;
  }
}

#header .hed_R {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #header .hed_R {
    display: none;
  }
}

#header .hed_R .hed_cont a {
  width: 200px;
  background: #e60012;
  padding: 10px;
  display: block;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#header .hed_R .hed_cont a img {
  margin-right: 7px;
}

#header .hed_R .telwrap {
  margin-right: 15px;
}

#header .hed_R .telwrap .time {
  font-size: 13px;
}

#header .hed_R .telwrap .tel2 {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}

#header .hed_R .telwrap .tel2 a {
  color: #48290a;
  font-weight: bold;
}

#header .hed_R .telwrap .tel2 img {
  margin-right: 7px;
}

@media screen and (max-width: 834px) {
  #header {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  #header {
    display: block;
  }
}

#header #siteID {
  text-align: center;
}

@media screen and (max-width: 834px) {
  #header #siteID {
    text-align: left;
  }
}

#header #siteID a {
  opacity: 1;
  display: inline-block;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: #fafafa;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
}

#nav_global ul li a {
  width: 100%;
  display: block;
  color: #48290a;
  line-height: 1.2em;
  padding: 10px;
}

@media only screen and (max-width: 1200px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul li a span {
  font-size: 13px;
  color: #fbed05;
  display: block;
  line-height: 1.2em;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

#nav_global ul ul li {
  border-bottom: 1px solid #f06c89;
}

#nav_global ul ul li a {
  color: #48290a;
  padding: 15px 0;
}

.mainwrap {
  position: relative;
}

.mainwrap .mainArea img {
  width: 100% !important;
  height: 900px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .mainwrap .mainArea img {
    height: auto !important;
  }
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: 5%;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 800px;
  max-height: 170px;
}

@media screen and (max-width: 834px) {
  .mainwrap .main_txt {
    max-height: 140px;
    max-width: 450px;
    left: 0;
    right: 0;
  }
}

.pankuzu {
  display: flex;
  align-items: center;
  justify-content: center;
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  font-size: 3em;
  z-index: 1;
  color: #fff;
  position: relative;
  text-align: center;
  line-height: 1.2em;
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
  font-weight: bold;
  border-bottom: 2px solid #ffa243;
  text-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
}

#local-keyvisual h1 span {
  font-size: 20px;
  color: #f7e14b;
  display: block;
}

#local-keyvisual h1:empty {
  display: none;
}

@media screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2em;
    padding: 8% 0;
  }
}

#slider {
  position: relative;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center top;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
  text-align: center;
  font-size: 13px;
}

@media screen and (max-width: 834px) {
  .pan1 {
    margin: 20px 0 50px;
  }
}

.pan1 a {
  color: #333;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #f7e14b;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  padding: 80px 50px !important;
}

.overlayMenu > .outer .sp_info {
  margin-top: 30px;
  background: #FDF8F4;
  padding: 15px;
  border: 1px solid #ffa243;
}

.overlayMenu > .outer .sp_info .contwrap .time {
  font-size: 13px;
  margin-top: 15px;
}

.overlayMenu > .outer .sp_info .contwrap .telwrap {
  margin-right: 15px;
}

.overlayMenu > .outer .sp_info .contwrap .telwrap .tel2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.overlayMenu > .outer .sp_info .contwrap .telwrap .tel2 a {
  color: #48290a;
  font-weight: bold;
}

.overlayMenu > .outer .sp_info .contwrap .telwrap .tel2 img {
  margin-right: 7px;
}

.overlayMenu > .outer .sp_info .contwrap .hed_cont a {
  width: 200px;
  background: #e60012;
  padding: 10px;
  display: block;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 15px auto;
}

.overlayMenu > .outer .sp_info .contwrap .hed_cont a img {
  margin-right: 7px;
}

.overlayMenu > .outer li {
  position: relative;
}

.overlayMenu > .outer li a {
  color: #48290a;
  padding: 15px;
  display: block;
  font-size: 1.2em;
  border-bottom: 1px solid #FDF8F4;
  font-weight: bold;
  line-height: 1.2em;
}

.overlayMenu > .outer .toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;
  padding: 29px;
}

.overlayMenu > .outer .sp_btn {
  position: relative;
}

.overlayMenu > .outer .sp_btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #f6963d;
  content: '';
}

.overlayMenu > .outer .sp_btn:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #f6963d;
  content: '';
  transition: transform .3s;
  transform: rotate(-90deg);
}

.overlayMenu > .outer .on_click:after {
  transform: rotate(0);
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: block;
  }
}

footer#global_footer #nav_footer ul {
  margin-right: 30px;
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    margin-right: 0;
  }
}

footer#global_footer #nav_footer li {
  text-align: left;
  position: relative;
}

footer#global_footer #nav_footer li:before {
  font-family: "Font Awesome 5 pro";
  content: "\f0a9";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  left: 0;
  /*アイコンの位置*/
  top: 4px;
  /*アイコンの位置*/
  color: #ffa243;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer li:before {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer li {
    text-align: center;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  line-height: 2em;
  padding: 0 0 0 20px;
  display: block;
  color: #48290a;
}

@media only screen and (max-width: 960px) {
  footer#global_footer #nav_footer li a {
    font-size: 14px;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a {
    background: #f7e14b;
    color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 50px;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #fff;
}

.small_bg {
  background: #ffa243;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f6963d;
  color: #fff;
  font-weight: bold;
  display: block;
  padding: 14px;
  font-size: 1.5em;
}

.footer1 {
  background: url(../images/home/contact_bg.jpg);
  background-size: cover;
  padding: 120px 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 834px) {
  .footer1 {
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .footer1 {
    justify-content: center;
  }
}

.footer1:after {
  content: '';
  background: url(../images/home/ourContact_deco.png) no-repeat right;
  position: absolute;
  width: 890px;
  height: 155px;
  right: 0;
  bottom: -8px;
}

@media screen and (max-width: 640px) {
  .footer1:after {
    width: 480px;
    height: 50px;
    background-size: 80%;
    bottom: 0;
  }
}

.footer1 .ft_h2 {
  font-size: 1.7em;
  font-weight: bold;
}

.footer1 .fttxt {
  margin: 30px 0;
  text-align: center;
}

.footer1 .ftwrap {
  background: rgba(255, 255, 255, 0.5);
  padding: 15px;
}

.footer1 .ftwrap .ftcont {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .footer1 .ftwrap .ftcont {
    display: block;
  }
}

.footer1 .ftwrap .ftcont .tel2 {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  margin-right: 15px;
}

@media screen and (max-width: 834px) {
  .footer1 .ftwrap .ftcont .tel2 {
    text-align: center;
    margin-bottom: 15px;
  }
}

.footer1 .ftwrap .ftcont .tel2 a {
  color: #48290a;
  font-weight: bold;
}

.footer1 .ftwrap .ftcont .tel2 img {
  margin-right: 7px;
}

.footer1 .ftwrap .ftcont .hed_cont a {
  width: 200px;
  background: #e60012;
  padding: 10px;
  display: block;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media screen and (max-width: 834px) {
  .footer1 .ftwrap .ftcont .hed_cont a {
    margin: 0 auto;
  }
}

.footer1 .ftwrap .ftcont .hed_cont a img {
  margin-right: 7px;
}

.footer1 .ftwrap .time {
  margin-top: 15px;
}

.footer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 10px;
}

@media screen and (max-width: 834px) {
  .footer2 {
    display: block;
  }
}

.footer2 .ft_L {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .footer2 .ft_L {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .footer2 .ft_L {
    width: 60%;
  }
}

.footer2 .ft_R {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .footer2 .ft_R {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .footer2 .ft_R {
    width: 40%;
  }
}

.footer2 .ft_R iframe {
  max-height: 217px;
}

.footer2 .ftlogo {
  text-align: left;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .footer2 .ftlogo {
    text-align: center;
  }
}

.title_01 h2 {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  line-height: 1.5em;
}

@media screen and (max-width: 640px) {
  .title_01 h2 {
    font-size: 26px;
  }
}

.title_01 h2 span {
  font-size: 14px;
  color: #f7e14b;
  display: block;
  line-height: 1em;
}

.title_02 h2 {
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .title_02 h2 {
    font-size: 26px;
  }
}

.title_02 h2 span {
  font-size: 14px;
  color: #ffa243;
  display: block;
}

.sub_title_01 h3 {
  font-size: 26px;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .sub_title_01 h3 {
    font-size: 20px;
  }
}

.sub_title_02 h3, .sub_title_03 h3 {
  font-size: 1.6em;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 640px) {
  .sub_title_02 h3, .sub_title_03 h3 {
    font-size: 20px;
  }
}

.sub_title_02 h3:after, .sub_title_03 h3:after {
  content: url(../images/home/midashi_icon.png);
  position: absolute;
  right: -40px;
  top: -10px;
}

.sub_title_03 h3:after {
  content: url(../images/home/midashi_icon2.png);
}

.bg01 {
  background: #fdf8f4;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 100px 10px;
}

@media screen and (max-width: 834px) {
  .bg01 {
    padding: 60px 10px;
  }
}

.cont_01 {
  background: url(../images/home/rec_bg.jpg);
  background-size: cover;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 100px 10px;
}

@media screen and (max-width: 834px) {
  .cont_01 {
    padding: 60px 10px;
  }
}

.cont_01 .txt {
  color: #fff;
}

@media screen and (max-width: 640px) {
  .cont_01 .txt div {
    text-align: left !important;
  }
}

.cont_01 .txt br:nth-of-type(1), .cont_01 .txt br:nth-of-type(3) {
  display: none !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .txt br:nth-of-type(1), .cont_01 .txt br:nth-of-type(3) {
    display: block !important;
  }
}

.cont_01 .txt div {
  line-height: 2em;
}

.cont_02 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media screen and (max-width: 834px) {
  .cont_02 {
    display: block;
  }
}

.cont_02 .flxL {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_02 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_02 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .cont_02 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 .flxR {
    padding: 0 10px;
  }
}

.cont_02 .flxL {
  position: relative;
}

.cont_02 .flxL .img {
  height: 100%;
}

.cont_02 .flxL img {
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_02 .flxL .imgR, .cont_02 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
  }
}

.cont_02 .flxL:after {
  content: url(../images/home/ill01l.png);
  position: absolute;
  right: -70px;
  bottom: 0;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_02 .flxL:after {
    right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .cont_02 .flxL:after {
    right: 0;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1300px) {
  .cont_02 .flxL:after {
    right: 0;
  }
}

.cont_03 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_03 {
    display: block;
  }
}

.cont_03 .flxL {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_03 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_03 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_03 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (max-width: 834px) {
  .cont_03 .flxR {
    padding: 0 10px;
  }
}

.cont_03 .flxL {
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_03 .flxL .imgR, .cont_03 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.cont_03 .flxL:after {
  content: url(../images/home/ill02.png);
  position: absolute;
  left: -70px;
  bottom: 0;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_03 .flxL:after {
    left: 20px;
  }
}

@media screen and (max-width: 640px) {
  .cont_03 .flxL:after {
    left: 0;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1300px) {
  .cont_03 .flxL:after {
    left: 0;
  }
}

.cont_04 {
  position: relative;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.cont_04:after {
  content: '';
  width: 100%;
  height: 416px;
  display: block;
  background: #0064ac;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #009a44, #0064ac);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #009a44, #0064ac);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.cont_04 .inner {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 834px) {
  .cont_04 .inner {
    display: block;
  }
}

.cont_04 .inner .flxL {
  width: 49%;
}

@media screen and (max-width: 834px) {
  .cont_04 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_04 .inner .flxR {
  width: 49%;
}

@media screen and (max-width: 834px) {
  .cont_04 .inner .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (max-width: 834px) {
  .cont_04 .inner {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .cont_04 .inner {
    padding: 0 10px;
  }
}

.cont_04 .inner:after {
  content: url(../images/home/ill01l.png);
  position: absolute;
  left: -180px;
  bottom: 0;
}

.cont_04 .inner:before {
  content: url(../images/home/ill02.png);
  position: absolute;
  right: -120px;
  bottom: 0;
}

.cont_04 .inner .dl_01 {
  color: #fff;
  font-size: 14px;
}

.cont_04 .cont1 {
  padding-bottom: 80px;
}

.cont_05 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/pt01.gif);
  position: relative;
  padding: 80px 0;
}

.cont_05:after {
  content: '';
  background: url(../images/home/instagram_deco.png) no-repeat;
  position: absolute;
  width: 850px;
  height: 155px;
  bottom: -29px;
  left: 0;
}

@media screen and (max-width: 640px) {
  .cont_05:after {
    width: 260px;
    height: 50px;
    background-size: contain;
    bottom: 0;
  }
}

.instagram {
  max-height: 700px;
  overflow-y: auto;
  z-index: 1;
  position: relative;
}

.instagram .sns_list {
  border: none !important;
  flex-direction: inherit !important;
  display: block !important;
}

.instagram .sns_list > div {
  border-bottom: 1px solid #ccc !important;
  flex-direction: inherit !important;
}

@media screen and (max-width: 640px) {
  .instagram .sns_list > div {
    display: block !important;
  }
}

.instagram .sns_date {
  color: #48290a !important;
}

.instagram .sns_text {
  font-size: 16px !important;
  width: 80% !important;
}

@media screen and (max-width: 640px) {
  .instagram .sns_text {
    width: 100% !important;
  }
}

.instagram .sns_photo {
  width: 20% !important;
}

@media screen and (max-width: 640px) {
  .instagram .sns_photo {
    width: 100% !important;
  }
}

.btn a {
  display: block;
  max-width: 280px;
  background: #ffa243;
  color: #fff;
  margin-top: 20px;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.btn a {
  border-radius: 50px;
  position: relative;
  font-weight: bold;
}

.btn a:before {
  content: url(../images/home/arrow_icon.png);
  position: absolute;
  left: 20px;
  top: 15px;
}

.g_calendar iframe, .g_map iframe {
  width: 100%;
  height: 450px;
}

.dl_01 dt {
  border: none !important;
  display: block !important;
  align-items: flex-start !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
    width: 100% !important;
  }
}

.btn2 a {
  background: #ffa243;
  border-radius: 50px;
  color: #fff;
  position: relative;
  display: block;
  padding: 15px;
  max-width: 280px;
  margin: 0 auto;
  font-weight: bold;
}

.btn2 a:before {
  content: url(../images/home/arrow_icon.png);
  position: absolute;
  left: 20px;
  top: 15px;
}

.newswrap {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/pt01.gif);
  position: relative;
  padding: 80px 0;
}

.newswrap:after {
  content: '';
  background: url(../images/home/news_deco.png) no-repeat;
  position: absolute;
  width: 500px;
  height: 155px;
  bottom: -10px;
  right: 0;
}

@media screen and (max-width: 640px) {
  .newswrap:after {
    width: 180px;
    height: 50px;
    background-size: contain;
    bottom: 0;
  }
}

.newswrap .news {
  max-height: 230px;
  overflow-y: auto;
}

.newswrap .news dt {
  border: none !important;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .newswrap .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  padding: 10px 0;
}

@media screen and (max-width: 640px) {
  .newswrap .news dl {
    display: block !important;
  }
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.btn a {
  display: block;
  max-width: 300px;
  background: #ffa243;
  color: #fff;
  margin-top: 20px;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.title_03 h2 {
  font-size: 1.7em;
  font-weight: bold;
  padding: 10px;
  background: #f7e14b;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffa243, #f7e14b);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffa243, #f7e14b);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 10px;
  line-height: 1.2em;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .title_03 h2 {
    font-size: 1.2em;
  }
}

.title_04 h2 {
  font-size: 1.7em;
  font-weight: bold;
  line-height: 1.5em;
}

@media screen and (max-width: 640px) {
  .title_04 h2 {
    font-size: 1.2em;
  }
}

.sub_title_04 h3 {
  font-size: 1.6em;
  font-weight: bold;
  border-bottom: 6px solid;
  border-image: linear-gradient(to right, #009a44 0%, #0064ac 100%);
  border-image-slice: 1;
  padding-bottom: 10px;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  background: #fdf8f4;
  padding: 30px;
}

@media screen and (max-width: 834px) {
  .sec_01 {
    display: block;
  }
}

.sec_01 .flxL {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .sec_01 .imgR, .sec_01 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.sec_02 {
  background: #f7f7f7;
  padding: 15px;
}

.sec_03 {
  background: #fff;
  padding: 50px 30px;
  border: 6px solid;
  border-image: linear-gradient(to right, #009a44 0%, #0064ac 100%);
  border-image-slice: 1;
}

.sec_03 .cont2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec_03 .cont2 .txt {
  width: 68%;
}

@media screen and (max-width: 834px) {
  .sec_03 .cont2 .txt {
    width: 100%;
  }
}

.sec_03 .cont2 .img {
  width: 28%;
}

@media screen and (max-width: 834px) {
  .sec_03 .cont2 .img {
    width: 100%;
    text-align: center !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .sec_03 .cont2 .imgR, .sec_03 .cont2 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .sec_03 .cont2 {
    display: block;
  }
  .sec_03 .cont2 .img {
    margin-top: 15px;
  }
}

.sec_04 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .sec_04 {
    display: block;
  }
}

.sec_04 .flxL {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_04 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_04 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_04 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_04 .img {
  text-align: center !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .sec_04 .imgR, .sec_04 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.imgbox {
  justify-content: center;
}

.imgbox .box {
  width: 40%;
  text-align: center;
  margin: 2%;
}

.imgbox .box img {
  border: 6px solid;
  border-image: linear-gradient(to right, #009a44 0%, #0064ac 100%);
  border-image-slice: 1;
}

.td_01 td {
  border: 1px solid #f6dcc7;
  width: 25%;
}

.td_01 .td_top {
  background: #ffa243;
  color: #fff;
}

.td_01 .td_side {
  background: #fdf8f4;
}

.txtbox .box {
  color: #fff;
  font-weight: bold;
  background: #FF864F;
  padding: 15px;
  text-align: center;
  margin-right: 2%;
  border-radius: 10px;
  width: 40%;
  margin-bottom: 2%;
}

@media screen and (max-width: 640px) {
  .txtbox .box {
    width: 100%;
    margin-right: 0;
  }
}

.dl_02 dt {
  border: none !important;
  border-bottom: 2px solid #ffa243 !important;
  padding: 15px !important;
  display: block !important;
  align-items: flex-start !important;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .dl_02 dt {
    width: 100% !important;
    display: block !important;
  }
}

.dl_02 dd {
  border: none !important;
  border-bottom: 2px solid #fff !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_02 dd {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.dl_03 dt {
  background: #ffa243 !important;
  color: #fff;
  font-weight: bold;
  border: none !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_03 dt {
    width: 100% !important;
    display: block !important;
  }
}

.dl_03 dd {
  border: none !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_03 dd {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_03 dl {
    display: block !important;
  }
}

.list_01 li {
  position: relative;
  padding: 0 0 0 20px;
}

.list_01 li:before {
  content: '・';
  position: absolute;
  left: 0;
  top: 0;
  color: #34aed2;
}

.logobox {
  align-items: center;
  justify-content: space-between;
}

.logobox .box {
  width: 21%;
  margin-bottom: 2%;
}

@media screen and (max-width: 640px) {
  .logobox .box {
    width: 26%;
  }
}

.logobox .box img {
  width: 252px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 640px) {
  .logobox .box img {
    width: 100% !important;
  }
}

.workbox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.workbox .work_cont {
  width: 49% !important;
  margin-right: 2%;
  margin-bottom: 2%;
}

@media screen and (max-width: 640px) {
  .workbox .work_cont {
    width: 100% !important;
    margin-right: 0;
  }
}

.workbox .work_cont:nth-of-type(2n) {
  margin-right: 0;
}

.workbox .work_cont .box {
  width: 100% !important;
}

.workbox .work_cont .box:nth-of-type(1) img {
  width: 100% !important;
}

.menubox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.menubox .menu_cont {
  width: 49% !important;
  margin-right: 2%;
  margin-bottom: 2%;
}

@media screen and (max-width: 640px) {
  .menubox .menu_cont {
    width: 100% !important;
    margin-right: 0;
  }
}

.menubox .menu_cont:nth-of-type(2n) {
  margin-right: 0;
}

.menubox .menu_cont .box {
  width: 100% !important;
  text-align: center;
}

.menubox .menu_cont .box:nth-of-type(1) img {
  width: 100% !important;
}

.menubox .menu_cont .box:nth-of-type(2) {
  background: #f06c89;
  color: #fff;
  padding: 10px;
}

.mail {
  display: none;
}

.cont_tel {
  font-size: 2em;
}

.cont_tel .tel2 {
  font-weight: bold;
}

.cont_tel .tel2 a {
  color: #48290a;
  font-weight: bold;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #ffa243;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}
